<template>
  <v-container fluid>
    <v-card width="100%" height="auto" class="mx-auto" outlined color="transparent">
      <v-card outlined height="auto" class="mx-auto pa-2" color="transparent">
      <v-layout wrap>
        <v-select dense outlined rounded class="ma-1" style="height: 40px; width: 200px;"
          id="company" 
          ref="company" 
          label="경영체" 
          v-model="company"
          :items="companyItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다.."
          item-text="name"
          return-object
          @change="changeCompany()"
          @focus="comboCompanies()"
          clearable
          >
          <template v-slot:item="slotProps" >
                {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 200px;" 
          id="farm" 
          ref="farm" 
          label="농장/사업장" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 200px;"
          id="building" 
          ref="building" 
          label="축사/건물/구역" 
          v-model="building"
          :items="buildingItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeBuilding()"
          @focus="comboBuildings()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 250px;"
          id="room" 
          ref="room" 
          label="돈방/위치" 
          v-model="room"
          :items="roomItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeRoom()"
          @focus="comboRooms()"
          clearable
          >
          <!-- <template slot="selection" slot-scope="data">
                {{ data.item.name }}<span class="ml-1 items">{{data.item.code}}</span>
          </template> -->
          <template v-slot:item="slotProps" >
                 {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.code}}</span>
          </template>
        </v-select>
        <v-btn rounded-pill class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px"
          id="refreshList" 
          :loading="loading" 
          ref="refreshList" 
          @click="refreshList()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          새로고침
        </v-btn>
      </v-layout>
      </v-card>      

      <div class="ma-2 ml-4" style="letter-spacing: -0.8px;">
        <v-layout wrap class="mb-3">
          <v-icon color=#3a4f3f>mdi-fan</v-icon>
          <div class="ml-2"> {{ currentDT }} 컨트롤러 설정 출력 현황</div>
          <v-btn rounded-pill class="ml-2 rounded-pill summary-btn" style="height: 25px; width: 150px"
            v-show = "farm.code && services.fancontrol"
            @click="gotoSummary()">
            제어변경
          </v-btn>
        </v-layout>
      </div>

      <v-data-table dense height="580px" class="elevation-1 v-data-table1 pa-2" style="width: 100%; "
        id="items" 
        ref="items"
        :loading="loading" 
        :headers="headers"
        :items="items"
        :items-per-page= "500"
        no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
        fixed-header
        hide-default-footer
        sort-by="['farm','room']"
        group-by="building"
        show-group-by
        item-key="controller_key"
        single-select
        :item-class="itemRowBackground"
        @click:row="selectRow"
        >
        <template v-slot:group.header="{items, isOpen, toggle}">
          <th colspan="14">
            <v-icon @click="toggle">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon> 건물/구역: {{ items[0].building}}
          </th>
        </template>  
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import VueCookies from "vue-cookies";
// import Common from "@/utils/custom/common.js";

export default {
  name: 'SettingControl',

  data: () => ({
    items : [],
    headers: [
      { text: '농장/사업장', value: 'farm', width: "110px", sortable: false, groupable: false, class: 'green darken-4 white--text', fixed:true },
      { text: '축사/건물/구역', value: 'building', width: "100px", sortable: false, groupable: true, class: 'green darken-4 white--text', fixed:true },
      { text: '돈방/위치', value: 'room', width: "100px", sortable: false, groupable: false, class: 'green darken-4 white--text', divider: true, fixed:true},
      { text0: '', text: 'GW mac', align: 'center', sortable: false, value: 'mac', width: "30px", groupable: false, class: 'green darken-4 white--text'},
      { text0: 'slave', text: 'Slave ID', align: 'right', sortable: false, value: 'slave_id', width: "20px", groupable: false, class: 'green darken-4 white--text', divider: false,},
      { text0: '', text: '종류', align: 'center', sortable: false, value: 'control_division', width: "60px", groupable: false, class: 'green darken-4 white--text', divider: true,},
      { lb: true, text0: '설정온도', text: '설정온도', align: 'right', sortable: false, value: 'set_temp', width: "100px", groupable: false, class: 'green darken-4 white--text', divider: true,},
      { lb: true, text0: '온도편차', text: '온도편차', align: 'right', sortable: false, value: 'dev_temp', width: "80px", groupable: false, class: 'green darken-4 white--text', divider: true,},
      { lb: true, text0: '최대출력', text: '최대출력 %', align: 'right', sortable: false, value: 'max_vent', width: "100px", groupable: false, class: 'green darken-4 white--text', divider: true,},
      { lb: true, text0: '최소출력', text: '최소출력 %', align: 'right', sortable: false, value: 'min_vent', width: "100px", groupable: false, class: 'green darken-4 white--text', divider: true,},
      
      { lb: true, text0: '', text: '장치온도', align: 'right', sortable: false, value: 'now_temp', width: "80px", groupable: false, class: 'green darken-4 white--text',},
      { lb: true, text0: '', text: '현재출력', align: 'right', sortable: false, value: 'now_vent', width: "100px", groupable: false, class: 'green darken-4 white--text',},

      // { text: '설정', align: 'center', sortable: false, value: 'monitor', width: "40px", groupable: false, class: 'green darken-4 white--text',divider: true },
    ],
    editedIndex: -1,
    loading: false,
    msgFlag: true,

    currentDT: "",

    company : {},
    companyItems : [],
    farm : {},
    farmItems : [],
    building : {},
    buildingItems : [],
    room : {},
    roomItems : [],

    services:{}

  }),
  async created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    await this.comboCompanies();
    await this.comboFarms();
    await this.comboBuildings();
    await this.comboRooms();

    let cookie_info = VueCookies.get("settingControl_info"); // 이전 조회정보를 참조

    if (this.$route.params && this.$route.params.company ) {
      // console.log("params",this.$route.params);
      this.company = this.$route.params.company;
      this.farm = this.$route.params.farm;
      this.building = this.$route.params.building;
      this.room = this.$route.params.room;
    } else if (cookie_info) {
      // console.log("cookies",cookie_info);
        this.company = cookie_info.company || {};
        this.farm = cookie_info.farm || {};
        this.building = cookie_info.building || {};
    }
    this.refreshList();
 },

  methods: {
    listAccesableServices(){
      Apis.listAccesableServices({
        farm_cd: this.farm && this.farm.code || "",
      },(res) => {  
        // console.log(res.data);
        this.services = res.data;
      }).catch( (err) => {  // API 오류 처리
          console.log("listAccesableServices API 호출 오류",err)
          alert(err);
      }) 
    },
    refreshList() {
      // if (!this.$route.params.building_cd) return;                // 모니터링 요약에서 랜딩
      this.currentDT = dateUtil.format(new Date(), "yyyy-MM-dd HH:mm" );
      if (this.msgFlag) this.$store.commit("resMessage","");
      this.loading = true;
      // console.log("리스트");
      Apis.listControllerSetting({
        company_cd: this.company && this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        building_cd: this.building && this.building.code || "",
        room_cd: this.room && this.room.code || "",
      } ,(res) => {  // 정상처리
        // console.log("res",res);
        res.data.forEach(element => {
          // element.sensor_mac = Common.getMacMask(element.sensor_mac);
          element.control_division = element.control_division.replace("컨트롤러","");
        });
        this.items = res.data;
        if (this.msgFlag) this.$store.commit("resMessage",res.message);
        this.msgFlag = true;
        this.loading = false;
        let cookie_info = {company: this.company, farm: this.farm, building: this.building };
        VueCookies.set("settingControl_info" ,cookie_info,"30d");

        // 현재 팬 상태값 세팅
        setTimeout( () =>  this.getNowControllerValue(), 1000);
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listControllerSetting 호출 오류",err);
        // alert("listControllerSetting 호출 오류" + err);
        this.$router.push("/");
        this.loadingRoom = false;
      }) 
    }, 
    getNowControllerValue() {
      this.items.forEach(((element,index) => {
        setTimeout( async function() {
          // console.log("",element.mac,element.slave_id);
          await Apis.getNowControllerValue({
            mac: element.mac,
            slave_id: element.slave_id,
          } ,(res) => { 
            // console.log("res",res);
            element.now_temp =  res.data.now_temp;
            element.now_vent =  res.data.now_vent;
            });
          },
          300 * index);
          }),)
    }, 
    selectRow(item,row) {
      row.select(true);
      // console.log("item-",item,"row=,",row)
    },
    itemRowBackground(item) {
      return item.building_cd == this.routedBuilding  ? 'selected' : '' ;
    },

    async comboCompanies() {
      await Apis.comboCompanies({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.companyItems = [];
            for (let i in res.data) {
            this.companyItems.push({
              code: res.data[i].company_cd,
              name: res.data[i].company_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboCompanies API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeCompany() {   // 하위 콤보 지우기;
      this.farm = {};
      this.building = {};
      this.room = {};
      this.sensor = {};
    },
    async comboFarms() {
      await Apis.comboFarms({
        company_cd: this.company && this.company.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
        this.listAccesableServices();
        this.company = {code: this.farm.company_cd, name: this.farm.company_name};
      }
      this.building = {};
      this.room = {};
      this.sensor = {};
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.building && this.building.company_cd) {
        this.company = {code: this.building.company_cd, name: this.building.company_name};
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
      }
        this.room = {};
        this.sensor = {};
    },
    async comboRooms() {
      await Apis.comboRooms({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        building_cd: this.building && this.building.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.roomItems = [];
            for (let i in res.data) {
            this.roomItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              building_cd: res.data[i].building_cd, 
              building_name: res.data[i].building_name, 
              code: res.data[i].room_cd,
              name: res.data[i].room_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboRooms API 호출 오류",err)
            alert(err);
        }
      ) 
    },
    async changeRoom() {      // 하위콤보 선택시 상위 콤보 동시 세팅
      if (this.room && this.room.company_cd) {
        this.company = {code: this.room.company_cd, name: this.room.company_name};
        this.farm = {code: this.room.farm_cd, name: this.room.farm_name};
        this.building = {code: this.room.building_cd, name: this.room.building_name};
        this.refreshList();
      }
    },
    gotoSummary() {
      this.$router.push({
        name: "ControlSummary",
        params: { company: this.company, farm: this.farm,building: this.building}
      });
    },


  }  // -----------methods 

}
// 01-06
</script>

<style lang="scss" scoped>
.v-data-table::v-deep{
  thead.v-data-table-header tr {
    th {
      background-color: #436251 !important;
      color: white !important;
      font-size:13px;
      height:40px !important;
    }
  }
}

.normal-btn{
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.summary-btn{
  color:#3a4f3f !important;
  border: solid 1px;
  border-color: #bcc7bc;
  font-weight: bold !important;
  background-color: white !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-weight: bold !important;
  background-color: #88191a !important;
}
.cell_top{
  // text-align:right;
  // font-weight: bold;
  padding-left: 2px;
  font-size: 12px;
  color: white;
  background-color: #1B5E20;
}
.cell_bottom{
  // text-align:right !important;
  // font-weight: bold;
  font-size: 12px;
  color: white !important;
  background-color: #1B5E20;
  }
.items {
  color: #a3a1a1;
  font-size: 14px;
  // font-weight: bold;
  text-align: left;
  letter-spacing: -1.2px;
}

</style>
